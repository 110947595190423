<template>
<div class="container">
  <div class="controller-area" style="font-size: 14px">
    <contract-single-diff-tool
        v-if="diffPdfInfo"
        :pdfInfo="diffPdfInfo"
        :scaleOptions="ScaleOptions"
        :alertData="alertData"
        :scale-value="scaleValue"
    ></contract-single-diff-tool>
  </div>
  <div class="pdf-area">
    <div class="pdf-container" :id="diffPdfContainerId">
      <div :id="diffFilePdfViewerId" class="pdfViewer">
      </div>
    </div>
  </div>
  <div class="alter-area">
    <div class="alter-container" :id="alertContainerId">
      <div
          v-for="(alertInfo,index) in alertData"
          :key="alertInfo.id"
          :data-id="alertInfo.id"
          @click="alertClick(alertInfo)"
          :ref="'alertInfoBox'+alertInfo.id"
          :data-expand-status="alertInfo.calculateInfo.status"
          class="diffAlertBox"
          :class="getDiffBoxClass(alertInfo)"
      >
        <div class="diffResultType">
          <span>{{index+1}}.{{getDiffAlertTypeDesc(alertInfo)}}</span>
        </div>
        <div v-if="getDeleteText(alertInfo)!==''" class="diffText">
          <span class="deleteText">{{(getDeleteText(alertInfo)).slice(0,60)}}</span>
          <span v-if="(getDeleteText(alertInfo)).length > 60" class="changeText_hiddenContentSummary">+ {{getDeleteText(alertInfo).length - 60}} 字</span>
        </div>
        <div v-if="getInsertText(alertInfo)!==''"  class="diffText">
          <span class="insertText">{{(getInsertText(alertInfo)).slice(0,60)}}</span>
          <span v-if="(getInsertText(alertInfo)).length > 60" class="changeText_hiddenContentSummary">+ {{getInsertText(alertInfo).length - 60}} 字</span>
        </div>
        <div class="diffResultAggravation">
          <span class="optionDiff optionDiff-delete" :class="{optionDiffNoChange:getDeleteText(alertInfo).length == 0}">{{getDeleteText(alertInfo).length > 0 ? '- ' + getDeleteText(alertInfo).length : 0}}</span>
          <hr class="optionDiff-hr">
          <span class="optionDiff optionDiff-add" :class="{optionDiffNoChange:getInsertText(alertInfo).length == 0}">{{getInsertText(alertInfo).length > 0 ? '+ ' + getInsertText(alertInfo).length : 0}}</span>
        </div>
      </div>
      <div v-if="alertDataReady&&alertData.length===0" id="noAlert">
        <div id="noAlertContent" style="height:100%;">
          <!--                <img src="https://uranus-static.oss-accelerate.aliyuncs.com/xiezuocat/isOk.png" width="100%">-->
          <div style="text-align: center;margin-top:50px;font-weight: bold">没有差异</div>
          <div style="text-align: center;flex: 1;position:relative">
            <div class="no-diff-container">
              <img id="no-diff-img" style="width:100%" src="../../../../public/static/img/no_diff.png">
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  convertContentIndexToPdfIndex,
  diffContractVersion, requestHeaderMixin,
} from "../../../../src/api/api";
import * as $ from "jquery";
import {getContractVersionPdfUrl, initPdfView, ScaleSpecialValue} from "../../../../src/components/common/pdfView";
import {ElLoading} from "element-plus";
import {FileTypeName} from "../../../../src/constant/contract";
import ContractSingleDiffTool from "../../../components/mobile/contractSingleDiffTool";

const DiffAlertType = {
  INSERT_BEFORE: 1,
  INSERT_AFTER: 2,
  DELETE_BEFORE: 3,
  DELETE_AFTER:4,
  REPLACE: 5,
}

const MarkTargetType = {
  ORIGIN_FILE: 1,//源文件
  DIFF_FILE: 2,// diff文件
};

const AlertShowType = {
  HORIZONTAL: 0,//水平划线模式
  VERTICAL: 1, // 垂直划线模式
  DOC: 2,//显示全文级别的错误
  NONE: 3,//不显示划线
  INSERT:4,//显示插入类型的划线
  DELETE:5,//显示删除类型的划线。
}

// 某一条提示信息的展示状态
const AlertDisplayStatus = {
  COLLAPSE: 0, // 折叠状态
  EXPAND: 1 // 展开状态
}

// diff的显示模式
const DiffDisplayMode = {
  SINGLE:0,// 单文件模式
  DOUBLE:1,// 双文件模式
}

const ScaleOptions = [
  {name:'自动缩放',value:'auto'},
  {name:'实际大小',value:'page-actual'},
  {name:'适合页面',value:'page-fit'},
  {name:'适合页宽',value:'page-width'},
  {name:'50%',value:'0.5'},
  {name:'100%',value:'1'},
  {name:'150%',value:'1.5'},
  {name:'200%',value:'2'},
  {name:'300%',value:'3'},
  {name:'400%',value:'4'},
];

export default {
  name: "contractSinglePageDiff",
  components: {ContractSingleDiffTool},
  mixins:[requestHeaderMixin],
  data(){
    return {
      alertData: [],
      DiffAlertType: DiffAlertType,
      AlertDisplayStatus: AlertDisplayStatus,
      alertHighlightClass: 'highlightContainer',
      alertDataMap:null,
      originPdfInfo: null,
      diffPdfInfo:null,
      diffFilePdfViewerId:'diff-pdf-viewer',
      pdfViewerId: 'pdf-viewer',
      diffPdfContainerId:'pdf-container2',
      alertContainerId:'alertContainer',
      alertDataReady: false,
      originVersionId: this.$route.query.oId,
      diffVersionId: this.$route.query.dId,
      contractId: this.$route.params.contractId,
      loadingInstance:null,
      diffDisplayMode:DiffDisplayMode.SINGLE,
      DiffDisplayMode,
      ScaleOptions,
      scaleValue: ScaleSpecialValue.PAGE_WIDTH

    }
  },
  mounted() {
    if (!this.checkParameter()) {
      return;
    }
    this.addEditorEventListener();
    this.startDiff();
  },
  watch:{
    alertData(val) {
      this.refreshAlertDataMap(val)
      this.refreshErrorHighlight(val);
    }
  },
  computed:{
    scrollerId:function () {
      return this.diffPdfContainerId;
    }
  },
  methods:{
    getDiffAlertTypeDesc: function (alert) {
      if (this.isDeleteAlert(alert)) {
        return "删除"
      }else if (this.isInsertAlert(alert)) {
        return "插入"
      }else if (alert.alertType === DiffAlertType.REPLACE) {
        return "替换"
      }
      return "";
    },
    refreshAlertDataMap: function (alertData){
      let res = new Map();
      for (let i = 0; i < alertData.length; i++) {
        res.set(alertData[i].id, alertData[i]);
      }
      this.alertDataMap = res;
    },
    startDiff: function () {
      if (!this.loadingInstance) {
        this.loadingInstance = ElLoading.service({ fullscreen: true, body: true, lock: true, spinner: 'el-icon-loading' });
      }
      this.loadingInstance.setText("对比中")
      diffContractVersion(this.originVersionId, this.diffVersionId).then(res => {
        console.log('diff result:', res);
        if (res.data.code === 0) {
          this.loadingInstance.close();
          this.diffSuccess(res);
        } else if (res.data.code === 1001) {
          // 说明还没有准备好，需要等待一段时间再次diff。
          setTimeout(() => {
            this.startDiff()
          }, 2000);
        } else {
          this.loadingInstance.setText("对比失败")
          this.$message.error(res.data.msg);
        }
      }).catch(error => {
        this.loadingInstance.setText("对比失败")
        // this.$message.error('对比失败');
      });
    },
    diffSuccess: function (res) {
      let alerts = res.data.data.alerts;
      this.alertDataBeforeHandle = alerts;
      this.originFileId = res.data.data.originFileId;
      this.diffFileId = res.data.data.diffFileId;
      this.initDiffPdfView(alerts);
    },
    initDiffPdfView:function(alerts){
      this.diffPdfInfoInitStarted=true;
      initPdfView({
        fileUrl:getContractVersionPdfUrl(this.diffVersionId,true),
        pdfContainerId: this.diffPdfContainerId,
        pdfViewerId: this.diffFilePdfViewerId,
        addPdfEventListener: this.addDiffPdfEventListener,
        defaultScale:this.scaleValue,
      }).then(diffPdfInfo =>{
        this.diffPdfInfo = diffPdfInfo;
        this.diffPdfContent = this.diffPdfInfo.replaceAllSpace(diffPdfInfo.pagesContent.join(""))
        this.pdfReady(alerts);
      });
    },
    addDiffPdfEventListener:function(eventBus){
      eventBus.on('pagerendered', (page) => {
        // console.log('diff file pageRendered',page)
        // 有时会出现虽然触发了pagerendered但是，页面节点还未渲染完毕的情况，所以做个timeout延迟处理。
        setTimeout(()=>{
          // console.log('diff file real do pageRendered',page)
          this.diffFilePageRendered(page.pageNumber);
        },100);
      });
    },
    pdfReady: function (alerts) {
      if ( !this.diffPdfInfo) {
        return;
      }
      if (!this.checkDiffResult(alerts)) {
        return;
      }
      this.convertAlertIndex(this.originVersionId,this.originPdfContent,this.diffVersionId,this.diffPdfContent, alerts);
    },
    checkDiffResult:function(alerts){
      console.log("check diff result****************")
      if (alerts.length === 1) {
        if (alerts[0].start === 0 && alerts[0].end === 0) {
          this.$message.error("未识别出左侧文档内容或左侧文档内容为空")
          return false;
        }else if (alerts[0].diffStart === 0 && alerts[0].diffEnd === 0) {
          this.$message.error("未识别出右侧文档内容或右侧文档内容为空")
          return false;
        }
      }
      return true;
    },
    diffFilePageRendered:function(pageNum){
      let highlightAlertData = this.alertData;
      let expandAlert =  this.getExpandAlert();

      this.refreshErrorHighlight(highlightAlertData);
      if (expandAlert && expandAlert.calculateInfo.diffPageIndexInfo && expandAlert.calculateInfo.diffPageIndexInfo[0].startPage === pageNum) {
        //如果展开的提示和加载出来的页面是同一页，则滚动过去。
        this.highlightClick(expandAlert.id, 500);
      }
    },
    convertAlertIndex: function (originVersionId,originPdfContent,diffVersionId,diffPdfContent, alerts) {
      convertContentIndexToPdfIndex({originVersionId,originPdfContent,diffVersionId,diffPdfContent, alerts}).then(res => {
        if (res.data.code !== 0) {
          this.$message.error('diff失敗')
          return
        }
        let newAlerts = res.data.data;
        this.startToMark(newAlerts)
      }).catch(error => {
        console.error(error)
        this.$message.error('diff失敗')
      })
    },
    /**
     * 刷新划线区域
     * @param {[{id,calculateInfo,showType,errorType}]} alertData
     */
    refreshErrorHighlight: function (alertData) {
      //删除旧的highlight节点
      this.removeHighlight()
      if (alertData.length === 0) {
        return
      }

      if (this.diffDisplayMode === DiffDisplayMode.SINGLE) {
        this.markVisibleAlert(alertData,MarkTargetType.DIFF_FILE);
      }else{
        this.markVisibleAlert(alertData,MarkTargetType.ORIGIN_FILE)
        this.markVisibleAlert(alertData,MarkTargetType.DIFF_FILE);
      }
    },
    startToMark: function (newAlertData) {
      console.log('start to mark', newAlertData)
      this.alertData = this.calculateAndModifyAlert(newAlertData)
      this.alertDataReady = true
      setTimeout(() => {
        this.refreshErrorHighlight(newAlertData);
      }, 1000)
    },
    removeHighlight: function () {
      $(`.${this.alertHighlightClass}`).remove()
    },
    markVisibleAlert:function(alertData,targetType){
      let self = this;
      let pdfInfo = this.getPdfInfoByTargetType(targetType);
      //先获取所有加载完毕的页码
      const visiblePageNum = pdfInfo.getRenderFinishPageNum(true);
      //根据已经加载出来的页码，来过滤能够显示出来的错误提示
      let canMarkDiffAlerts = alertData.filter(a => {
        if (!this.getPageIndexInfoByTargetType(a,targetType)) {
          return false;
        }
        return visiblePageNum.indexOf(this.getPageIndexInfoByTargetType(a,targetType)[0].startPage) !== -1;
      })
      //在已经加载出的页面内画出对应错误提示。
      canMarkDiffAlerts.forEach(alert => {
        self.getPageIndexInfoByTargetType(alert,targetType).forEach(pageIndexInfo => {
          //将页内坐标（包含空格）修复成页内元素坐标（不包含空格）
          const pageRanges = pdfInfo.getFixedPageRanges(pageIndexInfo)
          if (pageRanges == null) {
            //说明该页尚未加载完成，也就不用画错误提示。
            return;
          }
          //根据该坐标去取到对应的document的range对象。
          let documentRanges = pdfInfo.getRangeAndAppendLayers(pageRanges, visiblePageNum);
          documentRanges.forEach(rangeInfo => {
            //根据range对象对应的矩形区域画出错误提示。
            self.createAlertMarker(rangeInfo.appendLayer, rangeInfo.documentRange, alert,targetType);
          })
        });
      });
    },
    calculateAndModifyAlert: function (newAlertData) {

      let diffRanges = [];
      newAlertData.forEach((info) => {
        if (!(info.diffStart === 0 && info.diffEnd === 0)) {
          diffRanges.push({start: info.diffStart, end: info.diffEnd});
        }
      })
      const diffConverteRangeInfo = this.diffPdfInfo.fullTextIndexesConvertToPageIndexInfo(diffRanges)
      let diffRangeIndex = 0;
      newAlertData.forEach((info, index) => {
        if (!info.calculateInfo) {
          info.calculateInfo={status: AlertDisplayStatus.COLLAPSE}
        }
        if (!(info.diffStart === 0 && info.diffEnd === 0)) {
          diffRanges.push({start: info.diffStart, end: info.diffEnd});
          info.calculateInfo.diffPageIndexInfo = diffConverteRangeInfo.slice(diffRangeIndex, diffRangeIndex + 1);
          diffRangeIndex++;
        }
      })

      return newAlertData;
    },
    createAlertMarker: function (appendLayer, selectRange, alert,targetType) {

      //拿到range的前后两个span dom对象
      let rangeSpanPro = selectRange.startContainer.parentElement;
      let rangeSpanFor = selectRange.endContainer.parentElement;
      //存放这个range包含的node
      const nodesInRange = [];
      let tempNode = rangeSpanPro;

      if(rangeSpanPro != rangeSpanFor){
        //两个span dom对象 不是 同一个对象

        while(tempNode.nextSibling != rangeSpanFor){

          nodesInRange.push(tempNode);
          tempNode = tempNode.nextSibling;
        }
        nodesInRange.push(rangeSpanFor);
      }else{
        //两个span dom对象 是 同一个对象
        nodesInRange.push(rangeSpanPro);
      }

      //上一次遍历的是br元素
      let isBr = false;
      //nodes里面span的缩放值
      const computedScales = nodesInRange.map(span=>{
        if(span.nodeName.toLowerCase() != 'br'){
          isBr = false;
        }else{
          isBr = true;
        }
        //通过计算方法获取浏览器真实渲染出来的span的字体大小
        const realFontSize = Number(getComputedStyle(span).fontSize.slice(0,-2));
        //span标签上style属性上设定的span字体大小
        const setFontSize = Number(span.style.fontSize.slice(0,-2));

        //计算出缩放值
        const scale = (setFontSize / realFontSize).toFixed(2);
        const match = span.style.transform.match(/scaleX\((.+)\)/g);
        span.style.transform = (scale < 1 ? `scale(${scale})`:'') + (match ? ` scaleX(${RegExp.$1})` : '');

        return scale;
      }).filter(item => item);

      let selectRangeRects = selectRange.getClientRects();

      const offset = appendLayer.offset();
      let leftOffset = offset.left;
      let topOffset = offset.top;
      const self = this
      const lineBoxType = this.getAlertShowType(alert)
      const firstMarkerClasses = self.getAlertHighlightClassNames(alert,targetType);
      let notFirstMarkerClasses = this.excludeFirstMarkerClass(firstMarkerClasses);
      const firstMarkerClassStr = firstMarkerClasses.join(" ")
      const notFirstMarkerClassStr = notFirstMarkerClasses.join(" ")
      selectRangeRects = Array.from(selectRangeRects).filter(rect=>{
        return rect.width !== 0 && rect.height !== 0
      }).sort((a, b) => {
        if (Math.abs(a.top - b.top) > (a.height + b.height) * 0.5 * 0.5) {
          return a.top - b.top;
        }
        if (a.left !== b.left) {
          return a.left - b.left;
        }
        return 0
      })
      const mergedRects = lineBoxType === AlertShowType.VERTICAL ? this.mergeRectsByVerticalShowType(selectRangeRects, offset) : this.mergeRectsByAdjacent(selectRangeRects);
      for (let i = 0; i < mergedRects.length; i++) {
        let mergedRect = mergedRects[i];
        //由上面计算的缩放值造成的left偏移值
        // const leftOffsetByScale = mergedRect.width * (1 - needScale)/2

        appendLayer.append(
            `<div class='${i===0?firstMarkerClassStr:notFirstMarkerClassStr}' data-target="${targetType}" data-id="${alert.id}" style='left:${mergedRect.left - leftOffset }px;top:${mergedRect.top - topOffset }px;width:${mergedRect.width}px;height:${mergedRect.height}px;'></div>`
        )
      }
    },
    getAlertShowType: function (alert) {
      if (AlertShowType.VERTICAL === alert.showType) {
        return AlertShowType.VERTICAL
      } else if (AlertShowType.DOC === alert.showType) {
        return AlertShowType.DOC
      } else if (AlertShowType.NONE === alert.showType) {
        return AlertShowType.NONE
      }
      return AlertShowType.HORIZONTAL;
    },
    getAlertHighlightClassNames: function (alert,targetType) {
      let classArray = [this.alertHighlightClass];
      if (AlertDisplayStatus.EXPAND === alert.calculateInfo.status) {
        classArray.push('_3GrEs');
      }

      // const lineBoxType = this.getAlertShowType(alert)
      // classArray.push(lineBoxType === AlertShowType.VERTICAL ? 'verticalLineBox' : '_3F-Wk');
      classArray.push('single-mode-alert')

      if (MarkTargetType.ORIGIN_FILE === targetType){
        classArray.push('target-origin')
        if (DiffAlertType.INSERT_BEFORE === alert.alertType) {
          classArray.push('marker_color_insert');
          classArray.push('insert_before_alert');
        }
        if (DiffAlertType.INSERT_AFTER === alert.alertType) {
          classArray.push('marker_color_insert');
          classArray.push('insert_after_alert')
        }
        if (this.isDeleteAlert(alert)) {
          classArray.push('marker_color_delete');
          classArray.push('delete_alert')
        }
        if(DiffAlertType.REPLACE === alert.alertType) {
          classArray.push('marker_color_replace');
        }
      }else if (MarkTargetType.DIFF_FILE === targetType) {
        classArray.push('target-diff')
        if (this.isInsertAlert(alert)) {
          classArray.push('marker_content_border');
          classArray.push('marker_border_color1');
        }
        if (DiffAlertType.DELETE_BEFORE === alert.alertType) {
          classArray.push('marker_left_insert');
          classArray.push('marker_insert_color2')
        }
        if (DiffAlertType.DELETE_AFTER === alert.alertType) {
          classArray.push('marker_right_insert');
          classArray.push('marker_insert_color2')
        }
        if(DiffAlertType.REPLACE === alert.alertType) {
          classArray.push('marker_content_border');
          classArray.push('marker_border_color1');

          classArray.push('marker_left_insert');
          classArray.push('marker_insert_color2')
        }
      }

      return classArray
    },
    excludeFirstMarkerClass:function(classNames){
      let res = [].concat(classNames)
      let onlyFirstMarkerClass=['marker_left_insert','marker_right_insert'];
      for (let i = 0; i < onlyFirstMarkerClass.length; i++) {
        let index = res.indexOf(onlyFirstMarkerClass[i]);
        if (index >= 0) {
          res.splice(index, 1);
        }
      }
      return res;
    },
    /**
     * 根据竖直显示模式来合并矩形区域
     * @param {[{top,left,width,height}]} selectRangeRects
     * @param {[{top,left}]} offset
     */
    mergeRectsByVerticalShowType: function (selectRangeRects, offset) {
      if (selectRangeRects.length === 0) {
        return [];
      }
      let pageRect = this.originPdfInfo.getPeerPageRect()
      let res = {
        top: selectRangeRects[0].top,
        // left:selectRangeRects[0].left,
        // 划线统一从左到右
        left: offset.left + 15,
        // width:selectRangeRects[0].width,
        width: pageRect.width - 30,
        height: selectRangeRects[0].height
      };
      if (selectRangeRects.length < 2) {
        return [res];
      }
      for (let i = 0; i < selectRangeRects.length; i++) {
        // let x2 = Math.max(res.left + res.width, selectRangeRects[i].left + selectRangeRects[i].width);
        // res.left = Math.min(res.left, selectRangeRects[i].left);
        // res.width = x2 - res.left;

        let y2 = Math.max(res.top + res.height, selectRangeRects[i].top + selectRangeRects[i].height);
        res.top = Math.min(res.top, selectRangeRects[i].top);
        res.height = y2 - res.top;
      }
      return [res]
    },
    /**
     * 对获取到的DOMRectList里面的DOMRect进行去重，防止同一个位置出现多个选区选中,并将左右相邻很近的rect尽量连接起来。
     * @param {DOMRectList} selectRangeRects
     */
    mergeRectsByAdjacent: function (selectRangeRects) {
      if (selectRangeRects.length < 2) {
        return selectRangeRects
      }
      let result = [];
      let mergeRect = {
        top: selectRangeRects[0].top,
        left: selectRangeRects[0].left,
        width: selectRangeRects[0].width,
        height: selectRangeRects[0].height
      };
      for (let i = 1; i < selectRangeRects.length; i++) {
        let domEle = selectRangeRects[i]
        if (this.rectCanMerge(mergeRect, domEle)) {
          //如果前一个rect和现在的rect能够merge，则对它们进行融合
          let maxBottom = Math.min(mergeRect.top + mergeRect.height, domEle.top + domEle.height);
          let minTop = Math.min(mergeRect.top, domEle.top);

          let maxRight = Math.max(mergeRect.left + mergeRect.width, domEle.left + domEle.width);
          let minLeft = Math.min(mergeRect.left, domEle.left);

          mergeRect.top = minTop;
          mergeRect.height = maxBottom - minTop;
          mergeRect.left = minLeft;
          mergeRect.width = maxRight - minLeft;
        } else {
          //如果不能够merge，则将mergeRect作为一个range放到结果里面
          result.push(Object.assign({}, mergeRect));
          //并将当前rect作为mergeRect
          mergeRect.top = domEle.top;
          mergeRect.left = domEle.left;
          mergeRect.width = domEle.width;
          mergeRect.height = domEle.height;
        }
      }
      //将最后一个rect放入结果中。
      result.push(Object.assign({}, mergeRect));
      return result;
    },
    /**
     * 判断两个矩形是否相交
     * @param {{top,left,width,height}} rect1 第一个矩形
     * @param {{top,left,width,height}} rect2 第二个矩形
     * @returns {boolean}
     */
    rectCanMerge: function(rect1,rect2){
      //x方向，两个矩形的中心点的距离
      const xCenterDistance = Math.abs((rect2.left + rect2.width / 2) - (rect1.left + rect1.width / 2))
      //x方向，两个矩形的边长的一半
      const xHalfOfTotalWidth = (rect1.width + rect2.width) / 2;
      //y方向，两个矩形的中心点的距离
      const yCenterDistance = Math.abs((rect2.top + rect2.height / 2) - (rect1.top + rect1.height / 2))
      //y方向，两个矩形的边长的一半
      const yHalfOfTotalHeight = (rect1.height + rect2.height) / 2;
      //x方向可以融合的宽度差，这里把高度作为一个字的宽度，取一个字的百分之70作为可以融合的宽度差
      const mergeWidthDiff = yHalfOfTotalHeight * 0.7;
      const maxHeightDiff = yHalfOfTotalHeight * 0.1;
      //如果相交，则两个中心点之间的距离肯定小于等于该方向的边长和的一半。
      return xCenterDistance - mergeWidthDiff <= xHalfOfTotalWidth && yCenterDistance + maxHeightDiff <= yHalfOfTotalHeight;
    },
    checkParameter: function () {
      if (!this.originVersionId) {
        this.$message.error('参数错误');
        return false;
      }
      if (!this.diffVersionId) {
        this.$message.error('参数错误');
        return false;
      }
      if (!this.contractId) {
        this.$message.error('参数错误');
        return false;
      }
      return true;
    },
    addEditorEventListener: function () {
      let self = this
      // this.getEditorJElement().off('click').on('click',function (e) {
      $(".pdfViewer").off('click').on('click',function (e) {
        if (!(e.pageX || e.pageY)) {
          // 如果不是一个正常的点击事件（有可能是有focus触发的）
          return
        }
        self.judgeHighlightClick(e)
      })
    },
    // 判断是否点击到错误文字，并决定是否触发点击事件。
    judgeHighlightClick: function (e) {
      let alertHighlights = this.getAlertHighlights();
      let clickAlertIds = []
      //获取点击点在哪些alert的高亮区域里面
      for (let i = 0; i < alertHighlights.length; i++) {
        let highlightEleRect = alertHighlights[i].getBoundingClientRect()
        if (e.clientX <= highlightEleRect.right && e.clientX >= highlightEleRect.left - 3
            && e.clientY <= highlightEleRect.bottom + 3 && e.clientY >= highlightEleRect.top) {
          clickAlertIds.push($(alertHighlights[i]).attr('data-id'))
        }
      }
      if (clickAlertIds.length === 0) {
        return
      }
      let minStartAlertId = null;
      let maxStart = -1;

      for (let i = 0; i < clickAlertIds.length; i++) {
        let alert = this.getAlertById(clickAlertIds[i]);
        //这里忽略了多个markRange的情况（要想彻底解决这个问题还是得找到点击的地方在哪个字周围）
        if (alert.markRanges[0].start > maxStart) {
          //当前提示的start更大，更精准
          maxStart = alert.markRanges[0].start
          minStartAlertId = clickAlertIds[i]
        }
      }
      if(minStartAlertId){
        this.highlightClick(minStartAlertId, 50);
      }
    },
    getAlertHighlights:function () {
      return $(`.${this.alertHighlightClass}`);
    },
    getAlertById:function(alertId){
      return this.alertDataMap.get(alertId);
    },
    highlightClick: function (currentId, time) {
      let highlight = this.getAlertHighlightJItem(currentId)
      if (highlight) {
        this.expandAlert(currentId)
        this.alertClick(this.getAlertById(currentId))
      }
    },
    getAlertHighlightJItem:function(alertId){
      return $(`.${this.alertHighlightClass}[data-id=${alertId}]`);
    },
    expandAlert: function (alertId) {
      if (this.alertData.length <= 0) {
        return
      }
      //清除旧的提示划线高亮状态。
      this.clearActiveHighlight();
      this.alertData.forEach(currentAlertObj => {
        currentAlertObj.calculateInfo.status =
            alertId === currentAlertObj.id
                ? AlertDisplayStatus.EXPAND
                : AlertDisplayStatus.COLLAPSE
      })
      this.lineHighlight(alertId)
    },
    alertClick: function (alertObj) {
      let time = 500
      // 如果marker对应的页面都还没有加载出来，则跳到对应页面去加载。
      let originFileMarkerNotReady,diffFileMarkerNotReady;
      diffFileMarkerNotReady = this.scrollToPageIfNeed(alertObj, MarkTargetType.DIFF_FILE);

      if (diffFileMarkerNotReady) {
        // 一旦有一个pdf没准备好，则直接返回。
        return;
      }
      this.alignAlertMarker(alertObj);
      this.expandAlert(alertObj.id);
    },
    clearActiveHighlight: function () {
      const highlightBox = $(`.${this.alertHighlightClass}._3GrEs`);
      if (highlightBox) {
        highlightBox.removeClass('_3GrEs');
      }
    },
    lineHighlight:function(alertId){
      let expandAlert = this.getExpandAlert();
      if (expandAlert) {
        let oldHighlight = this.getAlertHighlightJItem(expandAlert.id);
        oldHighlight.removeClass('_3GrEs')
      }

      let newHighlight = this.getAlertHighlightJItem(alertId);
      newHighlight.addClass('_3GrEs')
    },
    /**
     * 如果alert对应的marker所在页还没有加载，则跳到对应页面去。
     */
    scrollToPageIfNeed: function (alertObj, markerTargetType) {
      let pdfInfo = this.getPdfInfoByTargetType(markerTargetType);
      let pageIndexInfo = this.getPageIndexInfoByTargetType(alertObj,markerTargetType);

      const visiblePageNum = pdfInfo.getRenderFinishPageNum(true);
      if (visiblePageNum.indexOf(pageIndexInfo[0].startPage) < 0) {
        this.expandAlert(alertObj.id)
        pdfInfo.scrollByPage(pageIndexInfo[0].startPage)
        return true;
      }
      return false;
    },
    alignAlertMarker:function(alert){
      let diffMarker = this.getHighlightJItemByIdAndMarkerTargetType(alert.id, MarkTargetType.DIFF_FILE);
      let alertBox = this.getAlertBoxById(alert.id);
      let alertScroller = this.getAlertBoxScroller();
      let diffScroller = this.getScrollerByMarkerTargetType(MarkTargetType.DIFF_FILE)
      let scrollerHeight= diffScroller.height();
      let scrollerOffsetTop = diffScroller.offset().top;
      let boundValue = scrollerHeight * 0.2;// 上下百分之20都属于靠边，需要挪到中间去。
      let animateTime = 500;
      let alertOffset = alertBox.offset().top

      if (this.isHighlightEleOutOfScreen(diffMarker)) {
        diffMarker[0].scrollIntoView(false)
      }

      // 单文件模式调整位置，
      let diffMarkerOffsetTop = diffMarker.offset().top
      if (Math.abs(diffMarkerOffsetTop - scrollerOffsetTop) < boundValue || Math.abs(scrollerHeight + scrollerOffsetTop - diffMarkerOffsetTop) < boundValue) {
        // 如果太靠上或者太靠下，都移到中间去。
        let destOffset = (scrollerHeight + scrollerOffsetTop) / 2;
        this.scrollToRelativePositionByMarkerTargetType(MarkTargetType.DIFF_FILE, diffMarkerOffsetTop - destOffset, animateTime);
        this.scrollToRelativePosition(alertScroller, alertOffset - destOffset, animateTime);
      } else {
        //说明在视觉区域中间，那么只需要调整alert box的位置。
        this.scrollToRelativePosition(alertScroller, alertOffset - diffMarkerOffsetTop, animateTime);
      }

    },
    getExpandAlert: function () {
      for (let i = 0; i < this.alertData.length; i++) {
        if (this.alertData[i].calculateInfo.status === AlertDisplayStatus.EXPAND) {
          return  this.alertData[i]
        }
      }
      return  null;
    },
    getPdfInfoByTargetType:function(targetType){
      if (targetType === MarkTargetType.ORIGIN_FILE) {
        return this.originPdfInfo
      }else if (targetType === MarkTargetType.DIFF_FILE) {
        return  this.diffPdfInfo;
      }
      return this.originPdfInfo;
    },
    getPageIndexInfoByTargetType: function (alert, targetType) {
      if (targetType === MarkTargetType.ORIGIN_FILE) {
        return alert.calculateInfo.pageIndexInfo
      }else if (targetType === MarkTargetType.DIFF_FILE) {
        return alert.calculateInfo.diffPageIndexInfo
      }
      return alert.calculateInfo.pageIndexInfo
    },
    /**
     * 根据alertId和markerTargetType获取marker
     */
    getHighlightJItemByIdAndMarkerTargetType:function(alertId, markerTargetType){
      let viewerId = this.pdfViewerId;
      if (MarkTargetType.DIFF_FILE === markerTargetType) {
        viewerId = this.diffFilePdfViewerId
      }
      return $(`#${viewerId} .${this.alertHighlightClass}[data-id=${alertId}]`);
    },
    getAlertBoxById:function(alertId){
      return $(this.$refs['alertInfoBox' + alertId])
    },
    getAlertBoxScroller:function(){
      return $(`#${this.alertContainerId}`)
    },
    getScrollerByMarkerTargetType:function(markerTargetType){
      let scrollerId = this.getScrollerIdByMarkerTargetType(markerTargetType);
      return $(`#${scrollerId}`);
    },
    isHighlightEleOutOfScreen: function (highlightEle) {
      let scrollerOffsetY = this.getScrollerOffsetTop() // 正文容器到可见区域顶部的距离
      let scrollerOffsetX = this.getScrollerOffsetLeft()
      let jqueryObj = $(highlightEle);
      let markerOffsetTop = jqueryObj.offset().top // 当前错误关键词到可见区域顶部的距离
      let markerOffsetLeft = jqueryObj.offset().left
      return markerOffsetTop > this.getScrollerHeight()
          || markerOffsetTop < scrollerOffsetY
          || markerOffsetLeft > this.getScrollerWidth()
          || markerOffsetLeft < scrollerOffsetX;
    },
    scrollToRelativePositionByMarkerTargetType:function(markerTargetType,addScrollTop, time){
      let scroller = this.getScrollerByMarkerTargetType(markerTargetType)
      return this.scrollToRelativePosition(scroller,addScrollTop,time)
    },
    scrollToRelativePosition: function (scroller,addScrollTop, time) {
      let currentTop = scroller.scrollTop()
      let realScrollTop = currentTop + addScrollTop
      this.scrollToAbsolutePosition(scroller,realScrollTop, time)
      return realScrollTop
    },
    scrollToAbsolutePosition: function (scroller,scrollTop, time) {
      if (time > 0) {
        scroller.animate({'scrollTop': scrollTop}, time);
      } else {
        scroller.scrollTop(scrollTop);
      }
    },
    getScrollerIdByMarkerTargetType:function(markerTargetType){
      if (MarkTargetType.DIFF_FILE === markerTargetType) {
        return this.diffPdfContainerId
      }
      return this.pdfContainerId;
    },
    // 正文容器到可见区域顶部的距离
    getScrollerOffsetTop: function () {
      return this.getScrollerJItem().offset().top;
    },
    // 正文容器到可见区域左侧的距离
    getScrollerOffsetLeft: function () {
      return this.getScrollerJItem().offset().left;
    },
    // 正文容器高度
    getScrollerHeight: function () {
      return this.getScrollerJItem().height()
    },
    // 正文容器宽度
    getScrollerWidth: function () {
      return this.getScrollerJItem().width()
    },
    getScrollerJItem: function () {
      // todo 待修改
      return $('#' + this.scrollerId)
      // return $('#' + this.diffScrollerId)
    },
    getDiffBoxClass:function(alert){
      let result = {}
      result['active'] = alert.calculateInfo.status === AlertDisplayStatus.EXPAND
      return result
    },
    getDeleteText:function(alert){
      if (this.isDeleteAlert(alert)) {
        return alert.sourceText.replace(/\s/g,'');
      }else if (this.isInsertAlert(alert)) {
        return ""
      }else if (alert.alertType === DiffAlertType.REPLACE) {
        return alert.sourceText.replace(/\s/g,'');
      }
    },
    isInsertAlert:function(alert){
      return DiffAlertType.INSERT_BEFORE === alert.alertType || DiffAlertType.INSERT_AFTER === alert.alertType;
    },
    isDeleteAlert:function(alert){
      return DiffAlertType.DELETE_BEFORE === alert.alertType || DiffAlertType.DELETE_AFTER === alert.alertType;
    },
    getInsertText: function (alert) {
      if (this.isDeleteAlert(alert)) {
        return ""
      }else if (this.isInsertAlert(alert)) {
        return alert.replaceText.replace(/\s/g,'');
      }else if (alert.alertType === DiffAlertType.REPLACE) {
        return alert.replaceText.replace(/\s/g,'');
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import url('../../../assets/css/alert.css');

$pdf-area-height:65%;

.container{
  width: 100vw;
  height: 100vh;
  background-color: var(--page-gray);
  display: flex ;
  flex-direction: column;
}
.pdf-area{
  flex-shrink: 1;
  height: $pdf-area-height;
  position: relative;
  overflow: hidden;

  .pdf-container {
    position: absolute;
    left:0;
    right:0;
    height: 100%;
    text-align: left;
    overflow: auto;

    .pdfViewer{
      width: 100%;
      height: auto;
      top: 0;
      /*overflow: hidden;*/
      text-align: left;
      display: inline-block;
      vertical-align: top;
      transition: width .5s;
    }
  }
}
.alter-area{
  flex-shrink: 1;
  height: 100% - $pdf-area-height;
  overflow-y: auto;
  box-sizing: border-box;
  border-top: 1px solid var(--gray-1);

  .alter-container{
    .diffAlertBox{
      color: rgba(0,0,0,.65);
      background: #fff;
      border: 1px solid rgba(0,0,0,.2);
      border-radius: 4px;
      margin: 12px 12px;
      position: relative;

      .diffResultType{
        color: #aaa;
        font-weight: 500;
        margin: 0 .25rem;
        line-height:1.5;
      }
      .deleteText{
        background: rgba(255,81,102,.4);
      }
      .insertText{
        background: rgba(91,193,165,.4);
      }
      .deleteText,.insertText{
        color: #2a2a2a;
        padding: 4px;
        word-break: break-all;
      }
      .diffResultAggravation{
        position: absolute;
        top: 5px;
        right: 5px;
        height: 1em;
        display: flex;
        font-size: 14px;
        line-height: 1em;

        .optionDiff-delete{
          color: rgb(236, 81, 99);
        }
        .optionDiff-add{
          color: rgb(4, 135, 126);
        }
        .optionDiff{
          padding: 0 8px;
          min-width: 20px;
          text-align: center;
        }
        .optionDiff-add{
          padding-right: 3px;
        }
        .optionDiffNoChange{
          color: #ccc !important;
        }
      }
    }
  }

  .diffAlertBox:hover{
    outline: 1px solid #42a5f5;
    border-color: transparent
  }
  .diffAlertBox.active{
    outline: 2px solid #0b7dda;
    border-color: transparent
  }
  #noAlert{
    height: 100%;
  }
  #noAlertContent{
    display: flex;
    height: 100%;
    flex-direction: column;
    position: relative;
  }
  #noAlertContent #no-diff-img{
    transform: scale(1);
    transform-origin: top;
    /*position: absolute;*/
    /*top: 20%;*/
    /*left: 0;*/
  }
  .no-diff-container{
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
    height: 100%;
  }
}

</style>
<style>
.insert_before_alert._3F-Wk:after{
  content:'\e612'!important;
  font-family: iconfont;
  bottom:4px!important;
  left:-10px;
  font-size:18px;
  font-weight: bolder;
  background: none;
  color:#5ec9db;
}
.insert_after_alert._3F-Wk:after{
  content:'\e612'!important;
  font-family: iconfont;
  bottom:4px!important;
  /*right:-10px;*/
  left:calc(100% - 10px);;
  font-size:18px;
  font-weight: bolder;
  background: none;
  color:#5ec9db;
}
.delete_alert._3F-Wk:after{
  bottom:40%!important;
}




.single-mode-alert,.double-mode-alert{
  position: absolute !important;
  margin: 0 !important;
  padding: 0 !important;
  /*border: 0 !important;*/
}
.single-mode-alert:before,.double-mode-alert:before{
  visibility: visible !important;
  content: '' !important;
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  left:0;
}
.single-mode-alert._3GrEs:before,.double-mode-alert._3GrEs:before{
  outline: 2px solid #0b7dda;
}
.double-mode-alert.marker_color_transparent._3GrEs:before{
  outline: none;
}

.marker_content_border{
  position: absolute;
  border-bottom: 3px solid;
}

.marker_border_color1{
  border-bottom-color: rgba(91,193,165,1);
}
.marker_border_color2{
  border-bottom-color: rgba(255,81,102,1);
}
.marker_right_insert:after{
  position: absolute;
  content:'\e601'!important;
  font-family: iconfont;
  bottom:-6px!important;
  right:-8px;
  /*left:calc(100% - 10px);;*/
  font-size:8px;
  font-weight: bolder;
  background: none;
  color:#5ec9db;
}
.marker_left_insert:after{
  position: absolute;
  content:'\e601'!important;
  font-family: iconfont;
  bottom:-8px!important;
  left:-5px;
  font-size:8px;
  font-weight: bolder;
  background: none;
  /*color:#5ec9db;*/
}
.marker_insert_color2:after{
  color:rgba(255,81,102,1);
}



.marker_color_transparent:before {
  background: transparent;
}
.marker_color_delete:before {
  background: rgba(255,81,102,0.4);
}
.marker_color_insert:before {
  background: rgba(91,193,165,0.4);
}


.diffResultType{
  color: #aaa;
  font-weight: 500;
  margin: 0 .25rem;
  line-height:1.5;
}
.diffText{
  color: #2a2a2a;
  line-height:1.5;
  margin: .5rem .75rem;
}
.deleteText,.insertText{
  color: #2a2a2a;
  padding: 4px;
  word-break: break-all;
}
.insertText{
  background: rgba(91,193,165,.4);
}
.deleteText{
  background: rgba(255,81,102,.4);
}
.diffAlertBox{
  color: rgba(0,0,0,.65);
  background: #fff;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 4px;
  margin: 12px 12px;
  position: relative;
}
.diffAlertBox:hover{
  outline: 1px solid #42a5f5;
  border-color: transparent
}
.diffAlertBox.active{
  outline: 2px solid #0b7dda;
  border-color: transparent
}

.pdfViewer .page[class] {
  width: 100%;
}


.single-mode-tool{
  display: none;
}
#noAlert{
  height: 100%;
}
#noAlertContent{
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;
}
#noAlertContent #no-diff-img{
  transform: scale(2);
  transform-origin: top;
  /*position: absolute;*/
  /*top: 20%;*/
  /*left: 0;*/
}
.no-diff-container{
  width: 80%;
  margin: 0 auto;
  overflow: hidden;
  height: 100%;
}
.switch-mode-item:hover{
  font-weight: bold !important;
  color:var(--lightBlue) !important;
}
/**/
</style>
