<template>
  <div class="contract-single-diff-tool">
    <div v-if="!(disableConfig?.scaleChange)" class="tool-container tool-scale-change">
      <button id="tool-scale-change-button" @click="showSwitchScaleDialog = true">
        <svg t="1646815804460" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2064" width="16" height="16"><path d="M511.9935 699.733c-102.4 0-187.733-85.333-187.733-187.733s85.333-187.733 187.733-187.733S699.7265 409.6 699.7265 512s-85.333 187.733-187.733 187.733z m472.177-250.311c-5.689-22.756-22.756-39.822-45.511-45.511l-11.378-5.689c-34.133-11.378-68.267-34.133-91.022-68.267s-22.756-73.956-17.067-113.778l5.689-11.378c5.689-17.067 0-45.511-17.067-62.578 0 0-17.067-11.378-56.889-34.133s-56.889-28.444-56.889-28.444c-22.756-5.689-45.511 0-62.578 17.067l-11.378 11.378c-28.444 22.756-68.267 39.822-108.089 39.822s-79.644-17.067-108.089-39.822l-5.689-17.067c-17.067-11.378-45.511-22.756-62.578-11.378 0 0-17.067 5.689-56.889 28.444s-56.889 34.133-56.889 34.133c-17.067 17.067-28.444 39.822-22.756 62.578l5.689 17.067c11.378 34.133 5.689 73.956-17.067 113.778-22.756 28.444-51.2 51.2-91.022 62.578l-11.378 5.689c-22.756 0-39.822 22.756-45.511 45.511 0 0-5.689 17.067-5.689 62.578s5.689 62.578 5.689 62.578c5.689 22.756 22.756 39.822 45.511 45.511l11.378 5.689c34.133 11.378 68.267 34.133 91.022 68.267s22.756 73.956 17.067 113.778l-5.689 11.378c-5.689 17.067 0 45.511 17.067 62.578 0 0 17.067 11.378 56.889 34.133s56.889 28.444 56.889 28.444c22.756 5.689 45.511 0 62.578-17.067l11.378-11.378c28.444-28.444 62.578-39.822 108.089-39.822 39.822 0 79.644 17.067 108.089 39.822l11.378 11.378c17.067 17.067 39.822 22.756 62.578 17.067 0 0 17.067-5.689 56.889-28.444s56.889-34.133 56.889-34.133 22.756-39.822 17.067-62.578l-5.689-17.067c-11.378-34.133-5.689-73.956 17.067-108.089s51.2-56.889 91.022-68.267l17.067-5.689c22.756-5.689 39.822-22.756 45.511-45.511 0 0 5.689-17.067 5.689-62.578-5.689-45.511-11.378-62.578-11.378-62.578z" p-id="2065"></path></svg>
        <span id="tool-scale-change-text">
          {{scaleOptions.find(item => item.value == toolScaleValue)?.name}}
        </span>
      </button>
    </div>
    <div v-if="!(disableConfig?.alertTip)" class="tool-container tool-alert-tip">
      <span>{{alertNum == 0 ? '无差异': alertNum + '处更改'}}</span>
    </div>
  </div>
  <Mask
      :display="showSwitchScaleDialog ? 'block':'none'"
      @clickVacancy="showSwitchScaleDialog = false"
  >
    <div class="mask-center-area insetCenter">
      <el-radio @click="changeScale(option.value)" v-for="option in scaleOptions" v-model="toolScaleValue" :label="option.value" size="large" border>
        {{
          option.name
        }}
      </el-radio>
    </div>
  </Mask>
</template>

<script>
import Mask from "../universalUI/Mask";
import {toRaw} from "vue";
export default {
  name: "contractSingleDiffTool",
  components: {Mask},
  props:["disableConfig","pdfInfo","alertData","scaleOptions","scaleValue"],
  data(){
    return{
      alertNum: this.alertData ? this.alertData.length : 0,
      toolScaleValue:'auto',
      showSwitchScaleDialog:false,
    }
  },
  watch:{
    scaleValue:{
      handler(val){
        this.toolScaleValue = val;
      },
      immediate:true
    },
    alertData:{
      handler(val){
        this.alertNum = val.length;
      },
      immediate:true
    }
  },
  created() {
    if(!this.pdfInfo){
      throw new Error('pdfInfo为null');
    }


  },
  methods:{
    changeScale(value){
      if(value == this.toolScaleValue) {
        return;
      }
      toRaw(this.pdfInfo).setScale(value);
    }
  },
}
</script>

<style scoped lang="scss">
.contract-single-diff-tool{
  *{
    background-color: inherit;
  }
  font-size: 1em;
  color: var(--font-gray);
  background-color: var(--page-gray);
  box-sizing: border-box;
  display: flex;
  padding: 4px 6%;
  border-bottom: 1px solid var(--gray-1);
  align-items: center;
  .tool-container{
    &:not(:first-child){
      margin-left: 1em;
    }
    &.tool-scale-change{

      > button#tool-scale-change-button{
        all:unset;
        &:active{
          filter: brightness(95%);
        }
        background-color: inherit;
        fill: var(--font-gray);
        display: flex;
        border-radius: 100px;
        border: 1px solid var(--gray-3);
        box-shadow: 0  0 3px 1px var(--gray-2);
        padding: .1em .4em;
        align-items: center;
        height: 1.5em;
        min-width: 5em;

        #tool-scale-change-text{
          flex-grow: 1;
          text-align: center;
        }
        svg.icon{
          width: 1em;
          height: 1em;
        }
      }
    }
  }
}

.mask-center-area{
  width: 90vw;
  padding: 5%;
  box-sizing: border-box;
  background-color: white;
  text-align:left;

  .el-radio{
    margin: 0;
    width: 33%;
  }
  .el-radio.is-bordered+.el-radio.is-bordered{
    margin-left: 0;
  }
}
</style>
<style scoped>
</style>
